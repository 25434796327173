
import script from '/home/circleci/app/packages/layout-user-session-component/src/entries/UserSession/index';
import { handleError } from '@fiverr-private/layout-lib/CSRTemplate/handleError';

try {
    script();
}
catch(error) {
    handleError(error, "UserSession", "UserSession")
}
