/**
 * The User session related query parameters.
 */
export const QUERY_PARAMS = {
    SHOW_JOIN: 'show_join',
    REFERRAL_EMAIL: 'referral_email'
} as const;

/**
 * The session storage key for managing an auto shown sign up modal.
 */
export const SIGN_UP_AUTO_SHOWN_KEY = 'sign_up_auto_shown';

/**
 * The auto show trigger reason to be reported on auto show sign up flow.
 */
export const AUTO_SHOW_TRIGGER = 'system_trigger';

/**
 * The User session modal handler types
 */
export const HANDLER_TYPES = {
    SIGN_IN: 'sign_in',
    SIGN_UP: 'sign_up'
} as const;

/**
 * The User session trigger selector mapped by the handler type.
 */
export const TRIGGER_SELECTORS = {
    [HANDLER_TYPES.SIGN_IN]: '.js-open-popup-login',
    [HANDLER_TYPES.SIGN_UP]: '.js-open-popup-join'
} as const;

/**
 * The User session fallback route mapped by the handler type.
 */
export const FALLBACK_ROUTES = {
    [HANDLER_TYPES.SIGN_IN]: 'login',
    [HANDLER_TYPES.SIGN_UP]: 'join'
} as const;

/**
 * The event types to platform mapping to report.
 */
export const EVENTS = {
    [HANDLER_TYPES.SIGN_IN]: {
        mixpanel: 'Clicked login',
        bigQuery: 'login_click'
    },
    [HANDLER_TYPES.SIGN_UP]: {
        mixpanel: 'Clicked join',
        bigQuery: 'signup_click'
    }
} as const;

/**
 * A map of signing global modal containers to be rendered by `user_session` package.
 * @see https://github.com/fiverr/user_session_package#external-modal-triggers
 */
export const GLOBAL_MODAL_CONTAINERS = {
    SIGN_IN: 'react-sign-in-modal',
    SIGN_UP: 'react-sign-up-modal',
    GOOGLE_ONE_TAP: 'react-google-one-tap'
} as const;
