import { getContext } from '@fiverr-private/fiverr_context';
import { bigQuery, mixpanel } from '@fiverr-private/obs';
import { init, renderEntries } from '@fiverr-private/user_session';
import { sessionStorage } from '@fiverr-private/futile';
import pathfinder from '@fiverr-private/pathfinder';
import * as Stats from '@fiverr-private/layout-lib/stats';
import {
    redirect
} from '../../utils';
import {
    HANDLER_TYPE,
    TRIGGER_MODAL
} from '../../types';
import {
    AUTO_SHOW_TRIGGER,
    FALLBACK_ROUTES,
    HANDLER_TYPES,
    QUERY_PARAMS,
    SIGN_UP_AUTO_SHOWN_KEY,
    TRIGGER_SELECTORS,
    EVENTS
} from '../../constants';

// **THIS MODULE IS TEMPORARY AND SHOULD BE REMOVED ONCE HEADER USES THE SESSION PACKAGE DIRECTLY**

/**
 * Gets requested parameter from current window SearchParams.
 * @param paramName - The param name to get.
 */
const getQueryParams = (paramName: string): string | null =>
    new URLSearchParams(window.location.search)
        .get(paramName);

/**
 * Creates sign up modal options.
 */
const getSignUpOptions = (optionsOverrides = {}): TRIGGER_MODAL['options'] => {
    const referralEmail = getQueryParams(QUERY_PARAMS.REFERRAL_EMAIL);

    const referralOptions = referralEmail ? {
        isSticky: true,
        initialValues: {
            email: decodeURIComponent(referralEmail)
        }
    } : {};

    return {
        ...referralOptions,
        ...optionsOverrides
    };
};

/**
 * Tracks a user session event type.
 * @param eventType The internal event type (One of EVENT_TYPES)
 * @param customBigQueryEvent The custom event to sent to big query additional to the default one.
 * @param sourceComponent The source component which triggered the event.
 */
const trackEvent = ({
    handlerType,
    customBigQueryEvent,
    sourceComponent
}: {
    handlerType: HANDLER_TYPE,
    customBigQueryEvent: string,
    sourceComponent: string,
}) => {
    const platforms = EVENTS[handlerType];

    mixpanel.track(platforms.mixpanel, {
        'Source Component': sourceComponent,
        'View Type': 'Pop-up'
    });

    bigQuery.send({
        type: platforms.bigQuery,
        group: 'signin flow'
    });

    if (customBigQueryEvent) {
        bigQuery.send({
            type: customBigQueryEvent,
            group: 'signin flow'
        });
    }
};

/**
 * Redirect to session fallback route.
 */
const fallbackRedirect = (handlerType: HANDLER_TYPE): void => {
    const routeKey = FALLBACK_ROUTES[handlerType];
    const route = pathfinder(routeKey);

    redirect(route);
};

/**
 * Triggers the signing modal. The user would be redirected to the fallback route
 * in case the trigger api method is not available or on mobile devices.
 */
const triggerModal = ({
    trigger,
    options = {},
    handlerType
}: TRIGGER_MODAL) => {
    const { isTouch } = getContext();

    if (isTouch && !options.forceModal) {
        fallbackRedirect(handlerType);
        return;
    }

    if (!trigger) {
        Stats.count(`signing_modal_trigger_failed.${handlerType.toLowerCase()}`);
        fallbackRedirect(handlerType);
        return;
    }

    trigger(options);
};

/**
 * Open Sign In modal.
 */
const triggerSignInModal = () => {
    triggerModal({
        trigger: window.openSignInModal,
        handlerType: HANDLER_TYPES.SIGN_IN
    });
};

/**
 * Open Sign Up modal.
 */
const triggerSignUpModal = (optionsOverrides = {}) => {
    triggerModal({
        trigger: window.openSignUpModal,
        options: getSignUpOptions(optionsOverrides),
        handlerType: HANDLER_TYPES.SIGN_UP
    });
};

/**
 * A map of element selectors, and their respective handler exported from `user_session` package.
 * @see https://github.com/fiverr/user_session_package#external-modal-triggers
 */
const LISTENER_MAP = {
    [HANDLER_TYPES.SIGN_IN]: triggerSignInModal,
    [HANDLER_TYPES.SIGN_UP]: triggerSignUpModal
} as const;

/**
 * Triggers user session click handler.
 */
const userSessionClickHandler = (event: Event, handlerType: HANDLER_TYPE) => {
    const { target } = event;

    if (!(target instanceof HTMLElement)) {
        return;
    }

    const {
        sourceComponent,
        eventType: customBigQueryEvent
    } = target.dataset as {
        sourceComponent: string,
        eventType: string,
    };

    event.preventDefault();

    trackEvent({
        handlerType,
        customBigQueryEvent,
        sourceComponent
    });

    LISTENER_MAP[handlerType]();
};

/**
 * Binds click handlers for all trigger elements selected by handler type.
 * @param handlerType - The signing flow handler type.
 */
const bindListener = (handlerType: HANDLER_TYPE) => {
    const triggerSelector = TRIGGER_SELECTORS[handlerType];
    const triggerElements = document.querySelectorAll(triggerSelector);

    triggerElements.forEach((triggerElement) =>
        triggerElement.addEventListener('click', (event) => {
            userSessionClickHandler(event, handlerType);
        }));
};

/**
 * Run user session logik on load.
 */
export const onLoad = () => {
    renderEntries();

    bindListener(HANDLER_TYPES.SIGN_IN);
    bindListener(HANDLER_TYPES.SIGN_UP);

    if (getQueryParams(QUERY_PARAMS.SHOW_JOIN)) {
        sessionStorage.set(SIGN_UP_AUTO_SHOWN_KEY, true);

        triggerSignUpModal({
            triggerType: AUTO_SHOW_TRIGGER,
            forceModal: true
        });
    }

    init();
};

/**
 * Handles client side initialization
 */
const main = () => {
    window.addEventListener('load', onLoad);
};

export default main;
